<template lang="html">
  <div class="not-found">
    <h1 class="not-found__404">404</h1>
    <p class="not-found__p">OOPS! Nothing was found</p>
    <router-link class="not-found__link" to="/">Return to homepage</router-link>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

.not-found
  width: 100%
  min-height: 100vh
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  background-color: #fff
  font-family: $lato
  &__404
    font-size: 256px
    font-weight: 400
    color: $pink-light
    text-shadow: 1px 1px $grey-title
    font-weight: 900
  &__p
    font-size: 26px
    margin-bottom: 25px
    color: $grey-text
    
  &__link
    font-size: 26px
    text-decoration: none
    color: $pink
    transition: .1s linear
    &::after
      content: ''
      display: block
      width: 100%
      border-bottom: 1px dashed $pink
      transition: .1s linear
    &:hover
      color: $red
      &::after
        opacity: 0
        transform: translateY(-5px)

</style>
